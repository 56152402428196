import { useEffect, useState } from "react";
import { ReadContext } from "../../Context";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { emailPasswordLogin, googleLogin } from "../../firebase";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Container,
  TextField,
  InputLabel,
  IconButton,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from "@mui/material";

export const Login = () => {
  const navigate = useNavigate();
  const { userData } = ReadContext();

  const ismobile = useMediaQuery("(min-width:500px)");

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      let res = await emailPasswordLogin(user, pass);
      setLoader(false);
      if (res) {
        navigate("/");
      } else {
        alert("Incorrect Email or Password");
      }
    } catch (err) {
      alert("Incorrect Email or Password");
      setLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (userData.emailVerified === false) setShowAlert(true);
  }, [userData]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          p: 5,
          mt: 1,
          mb: 5,
          boxShadow: 6,
          borderRadius: 3,
        }}
      >
        <Link to="/">
          <img src="/images/logo.png" alt="Logo" style={{ width: "100%" }} />
        </Link>
        <br />
        <br />
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            label="Email"
            placeholder="Enter Your Email"
            // value={user}
            onChange={(e) => setUser(e.target.value)}
            fullWidth
            required
          />
          <br />
          <br />
          <FormControl fullWidth required>
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              label="Password"
              placeholder="Enter Your Password"
              type={showPass ? "text" : "password"}
              // value={pass}
              onChange={(e) => setPass(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPass(!showPass)}>
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Box sx={{ my: 2 }}>
            <Link to="/forget-password">
              <Typography color="blue" sx={{ my: 1 }}>
                Forgot Password?
              </Typography>
            </Link>
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="themeDarkBlue"
            fullWidth
          >
            <Typography color="white">Login</Typography>
          </Button>
        </form>
        <br />
        <Link to="/sign-up">
          <Typography textAlign="center">
            Didn't Have an Account?
            <span style={{ color: "blue" }}>SignUp</span>
          </Typography>
        </Link>
        <br />
        {/* <Link to="/forget-password">
          <Typography color="blue" textAlign="center">
            Forgot Password?
          </Typography>
        </Link> */}
        {/* {
          ismobile ?
            <Button

              onClick={() => {
                googleLogin()
                  .then(() => navigate("/"))
                  .catch((err) => console.log(err));
              }}
              variant="outlined"
              color="primary"
              sx={{ p: 1 }}
              fullWidth
            >
              <img
                src="/images/google.png"
                alt="Google"
                style={{ width: "2rem" }}
              />
              &nbsp; &nbsp; &nbsp; &nbsp;
              <Typography variant="body2">Sign In With Google</Typography>
            </Button> : null
        } */}
        {/* <br />
        <br />
        <Button
          onClick={facebookLogin}
          variant="outlined"
          color="primary"
          sx={{ p: 1 }}
          fullWidth
        >
          <img
            src="/images/facebook.png"
            alt="Facebook"
            style={{ width: "2rem" }}
          />
          &nbsp; &nbsp; &nbsp; &nbsp;
          <Typography>Sign In With Facebook</Typography>
        </Button> */}
      </Box>

      <Snackbar
        open={showAlert}
        autoHideDuration={2500}
        onClose={() => setShowAlert(!showAlert)}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          onClose={() => setShowAlert(!showAlert)}
        >
          <Typography>
            <b>Please Verify Your Email</b>
          </Typography>
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
