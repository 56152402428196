import emailjs from '@emailjs/browser';

export const SendEmail = (data) => {
    console.log(data)
    try {
        let template = {
            Contractor: 'template_8azrrix',
            Customer: 'template_fobrh8k'
        }
        emailjs.send('service_4sxrbce', template[data.template_name], data, 'aZz7RB-_UbYBxQiX-')
            .then((result) => {
                console.log(result.text);


            }, (error) => {
                console.log(error.text);
            });
    } catch (err) {
        console.log(err, err)

    }

}